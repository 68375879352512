<template>
  <div>

    <v-card-title primary-title class="card-header mx-0 px-4 py-2">
      {{ $tc('label.contrato', 1) }} - {{ acao.idContrato }} ::
      {{ $t('label.acao') }} - {{ acao.idAcao }} ::
      {{ $tc('label.apuracao',1) }} - {{ acao.idApuracao }} ::
      {{ $tc('label.pagamento',1) }} - {{ acao.idPagamento }} ::
      {{ acao.tipoAcao }} ::
      {{ acao.divisao }}
      <popover-lista :objeto="formatarObjeto()" :campos="listaPopover()"/>
      <v-tooltip bottom v-if="canEditStatus">
        <template v-slot:activator="{ on }">
          <v-btn icon
            id="botao_atualiza_status"
            v-on="on"
            @click="abrirModalAtualizaStatus">
            <v-icon>sync</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('message.atualiza_pagamento') }}</span>
      </v-tooltip>
    </v-card-title>

    <div class="body-1 mx-3 px-2 pb-2">
      <div>{{ acao.descricao }}</div>
      <div class="my-2" v-if="exibirCampo('periodo_planejamento')">{{ $t('label.data_inicio') }}: {{ acao.dtaInicio }}</div>
      <div class="my-2" v-if="exibirCampo('periodo_planejamento')">{{ $t('label.data_fim') }}: {{ acao.dtaFim }}</div>
      <div class="my-2">{{ $tc('label.responsavel', 1) }}: {{ acao.responsaveis }}</div>
      <div  class="my-2" v-if="exibeValorPlanejado">
        {{`${$tc('label.valor_acao', 1)}: ${getMoney(acao.valor)}`}}
      </div>
    </div>
    <modal-atualiza-status
      :acao="acao"
      ref="modalAtualizaStatus"
      @RECARREGAR_PAGAMENTO="recarregar">
    </modal-atualiza-status>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import PopoverLista from '../../../shared-components/PopoverLista';
import { getMoney } from '../../../common/functions/helpers';
import ModalAtualizaStatus from './ModalAtualizaStatus';

export default {
  components: {
    PopoverLista,
    ModalAtualizaStatus,
  },
  props: {
    acao: Object,
    configuracao: Object,
    exibeValorPlanejado: Boolean,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...generateComputed('CTRT_PGTO', [
      'canEdit',
    ]),
    canEditStatus() {
      return this.canEdit && this.acao.statusPagamento !== 'CANCELADO' && this.acao.statusPagamento !== 'PROGRAMADO';
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    getMoney,
    exibirCampo(campo) {
      if (this.configuracao.passo3) {
        const { listaPadrao } = this.configuracao.passo3;
        return !!listaPadrao.filter((p) => p.fluxos.includes('LIQUIDACAO') && p.label === campo).length;
      }
      return false;
    },
    listaPopover() {
      const lista = [];
      lista.push({ campo: 'tipoContrato', label: this.$tc('label.tipo_contrato', 1) });

      if (this.exibirCampo('status')) {
        lista.push({ campo: 'status' });
      }
      if (this.exibirCampo('unidadenegocio')) {
        lista.push({ campo: 'unidadeNegocio', label: this.$tc('label.unidade_negocio', 1) });
      }
      if (this.exibirCampo('tipo_verba')) {
        lista.push({ campo: 'tipo_verba', label: this.$tc('label.tipo_verba', 1) });
      }

      if (this.configuracao.passo3) {
        const { listaDinamica } = this.configuracao.passo3;
        const camposLiquidacao = listaDinamica.filter((p) => p.fluxos.includes('LIQUIDACAO'));
        camposLiquidacao.forEach((a) => lista.push({
          campo: a.label,
          label: this.montaLabelCampoDinamico(a.label),
        }));
      }
      return lista.filter((campo) => campo.label !== 'campo_dinamico_inativo');
    },
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.statusPagamento) {
        acaoFormatada.status = this.$tc(`status_entidade.${this.acao.statusPagamento.toLowerCase()}`, 1);
      }
      return acaoFormatada;
    },
    montaLabelCampoDinamico(campo) {
      const metadado = this.getAcaoMetadado.mapaCamposDinamicos;
      if (!metadado[campo]) {
        $logger.log(campo);
        return 'campo_dinamico_inativo';
      }
      return metadado[campo].nomCampo;
    },
    abrirModalAtualizaStatus() {
      this.$refs.modalAtualizaStatus.open();
    },
    recarregar(novoStatus) {
      this.$emit('RECARREGAR_PAGAMENTO', novoStatus);
    },
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
